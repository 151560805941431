body { background-color: #ffffff; }
body { color: var(--clr-664); }
[data-pad="0"] { margin-top: 20px; }

[data-pad="1"] { margin-top: 5px; }

h1 {
font-family: 'NeoSans TR';
font-weight: 500;
font-style: italic;
line-height: 1;
letter-spacing: 0;
font-size: 25px;

@media #{$medium-up} {
font-size: 40px;

}
}
h2 {
font-family: 'NeoSans TR';
font-weight: 500;
font-style: italic;
line-height: 1.1;
letter-spacing: 0;
font-size: 20px;

@media #{$medium-up} {
font-size: 32px;

}
}
h3 {
font-family: 'NeoSans TR';
font-weight: 500;
font-style: italic;
line-height: 1.1;
letter-spacing: 0;
font-size: 25px;

@media #{$medium-up} {
font-size: 40px;

}
}
h4 {
font-family: 'NeoSans TR';
font-weight: 500;
font-style: italic;
line-height: 1.1;
letter-spacing: 0;
font-size: 17px;

@media #{$medium-up} {
font-size: 25px;

}
}
h5 {
font-family: 'NeoSans TR';
font-weight: normal;
font-style: italic;
line-height: 1.1;
letter-spacing: 0;
font-size: 20px;

}
h6 {
font-family: 'NeoSans TR';
font-weight: 500;
font-style: italic;
line-height: 0.9;
letter-spacing: 0;
font-size: 34px;

@media #{$medium-up} {
font-size: 60px;

}
@media #{$large-up} {
font-size: 80px;

}
}
.button {
font-family: 'NeoSans TR';
font-weight: 500;
font-style: italic;
line-height: 1.5;
text-transform: uppercase;
letter-spacing: 0;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
.me-Quote .quote-body, .me-HtmlText blockquote {
font-family: 'Arapey';
font-weight: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
.me-Quote .quote-author {
font-family: 'Noto Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
ol,ul {
font-family: 'Catamaran';
font-weight: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
ol,ul { margin-bottom: 1em; list-style-position: outside; }
ol { margin-left: 1.25em; list-style-type: decimal; }
ul { margin-left: 1.25em; list-style-type: disc; }
ul, ol { & & { margin-bottom: 0 } }
cite {
font-family: 'Noto Sans';
font-weight: normal;
font-style: normal;
line-height: 1.6;
letter-spacing: 0;
font-size: 18px;

@media #{$medium-up} {
font-size: 22px;

}
}
summary {
font-family: 'NeoSans TR';
font-weight: normal;
font-style: italic;
line-height: 1.5;
letter-spacing: 0;
font-size: 18px;

@media #{$medium-up} {
font-size: 20px;

}
}
nav.mainmenu > .menu-item > a {
font-family: 'NeoSans TR';
font-weight: 500regular;
font-style: normal;
line-height: 1.5;
text-transform: uppercase;
letter-spacing: 0;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
nav.mainmenu > .menu-item > div > a {
font-family: 'NeoSans TR';
font-weight: 500regular;
font-style: normal;
line-height: 1.5;
text-transform: uppercase;
letter-spacing: 0;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
nav.secondarymenu > .menu-item > a {
font-family: 'NeoSans TR';
font-weight: 500regular;
font-style: normal;
line-height: 1.5;
text-transform: uppercase;
letter-spacing: 0;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
nav.me-RespMenu.responsive-menu a {
font-family: 'NeoSans TR';
font-weight: 500regular;
font-style: normal;
line-height: 1.5;
text-transform: uppercase;
letter-spacing: 0;
font-size: 20px;

}
body {
font-family: 'Catamaran';
font-weight: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 16px;

@media #{$medium-up} {
font-size: 18px;

}
}
p { padding-bottom: 1em; }
a {color: var(--clr-665);}
a:hover {color: var(--clr-666);}
/* Primary:2 */
.MES2 {
background-color: var(--clr-665);
color: #ffffff;
 }
/* Primary:3 */
.MES3 {
background-color: var(--clr-665);
color: #ffffff;
 }
.MES3 {
background-color: var(--clr-665);
color: #ffffff;
h1.MEC3, h2.MEC3, h3.MEC3, h4.MEC3, h5.MEC3, h6.MEC3 { color: #ffffff;
 }
 }
cite.MEC3{
color: #ffffff;
}
/* Secondary:4 */
.MES4 {
background-color: var(--clr-666);
color: #ffffff;
&:hover { color: #ffffff;}
 }
/* Secondary:5 */
.MES5 {
background-color: var(--clr-666);
color: #ffffff;
 }
.MES5 {
background-color: var(--clr-666);
color: #ffffff;
h1.MEC5, h2.MEC5, h3.MEC5, h4.MEC5, h5.MEC5, h6.MEC5 { color: #ffffff;
 }
 }
cite.MEC5{
color: #ffffff;
}
/* Alternate:6 */
.MES6 {
background-color: var(--clr-667);
 }
/* Alternate:7 */
.MES7 {
background-color: var(--clr-667);
color: var(--clr-664);
 }
/* Alternate:8 */
.MES8 {
background-color: var(--clr-667);
 }
.MES8 {
background-color: var(--clr-667);
 }
/* Alternate:9 */
.MES9 {
background-color: var(--clr-667);
color: var(--clr-664);
 }
.MES9 {
background-color: var(--clr-667);
color: var(--clr-664);
h1.MEC9, h2.MEC9, h3.MEC9, h4.MEC9, h5.MEC9, h6.MEC9 { color: var(--clr-664);
 }
 }
cite.MEC9{
color: var(--clr-664);
}
/* Light:10 */
.MES10 {
background-color: var(--clr-668);
color: var(--clr-664);
 }
/* Light:11 */
.MES11 {
background-color: var(--clr-668);
color: var(--clr-664);
 }
.MES11 {
background-color: var(--clr-668);
color: var(--clr-664);
h1.MEC11, h2.MEC11, h3.MEC11, h4.MEC11, h5.MEC11, h6.MEC11 { color: var(--clr-664);
 }
 }
cite.MEC11{
color: var(--clr-664);
}
/* Shade 1:12 */
.MES12 {
background-color: var(--clr-669);
color: #ffffff;
 }
/* Shade 1:13 */
.MES13 {
background-color: var(--clr-669);
color: #ffffff;
 }
.MES13 {
background-color: var(--clr-669);
color: #ffffff;
h1.MEC13, h2.MEC13, h3.MEC13, h4.MEC13, h5.MEC13, h6.MEC13 { color: #ffffff;
 }
 }
cite.MEC13{
color: #ffffff;
}
/* Copyright panel:14 */
.MES14 {
font-size: 12.8px;
@media #{$medium-up} {
font-size: 14.4px;
};
 }
.MES14 {
font-size: 12.8px;
@media #{$medium-up} {
font-size: 14.4px;
};
 }
cite.MEC14{
font-size: 12.8px;
@media #{$medium-up} {
font-size: 14.4px;
};
}
/* News Grid:16 */
.MES16 {
background-color: transparent;
&:hover, &.hover { background-color: var(--clr-667);}
padding: 15px;

@media #{$large-up} {
padding: 40px;

}
border-width: 1px;
border-style: solid;
border-color: var(--clr-668);
border-top-style: solid;
border-right-style: solid;
border-bottom-style: solid;
border-left-style: solid;
 }
.MES16 {
background-color: transparent;
&:hover, &.hover { background-color: var(--clr-667);}
padding: 15px;

@media #{$large-up} {
padding: 40px;

}
border-width: 1px;
border-style: solid;
border-color: var(--clr-668);
border-top-style: solid;
border-right-style: solid;
border-bottom-style: solid;
border-left-style: solid;
 }
/* Main Menu:17 */
nav.me-Menu.MES17 {
& .menu-item.MEC17, & .menu-item.MEC17 > div.MEC17{ & > a.MEC17{color: var(--clr-664);
text-transform: uppercase;
}
 &:hover > a.MEC17{color: var(--clr-670);
}
 }
&.horizontal > .menu-item.MEC17 { border:0;

 border-right-width: 1px;}
&.vertical > .menu-item.MEC17 { border:0;

border-bottom-width: 1px; }
&.horizontal > .menu-item.MEC17 .menu-item { border:0;
border-bottom-width: 1px;
 }
&.horizontal > .menu-item.MEC17 .sub-menu { border:1px;
 }
& > .menu-item > a{padding: 15px;}

& .sub-menu .menu-item a{padding: 15px 20px;}



&.horizontal .menu-item.MEC17:hover {& > .pointer-wrap > .pointer{border-bottom: 10px solid var(--clr-664-flat);}}
&.vertical .menu-item.MEC17:hover {& > .pointer-wrap > .pointer{border-right: 10px solid var(--clr-664-flat);
}}

.menu-item:hover {
& > .sub-menu, & > .pointer-wrap > .sub-menu {
.menu-item.MEC17{background-color: var(--clr-664); &:hover {background-color: 16;}
}
& .menu-item.MEC17, & .menu-item.MEC17 > div.MEC17{ & > a.MEC17{color: #ffffff;
font-size: 14.4px;
}
 &:hover > a.MEC17{color: 2;
}
 }

}
}
 }
/* Paypal button:18 */
.MES18 {
background-color: var(--clr-671);
color: #ffffff;
 }
/* Home slider:19 */
.MES19 {
& .slider-arrow {color: var(--clr-672);
font-size: 100px;
@media #{$medium-up} {
font-size: 100px;
};
@media #{$large-up} {
font-size: 80px;
};
}& .slick-dots {text-align: center;
 button {margin: 0 1px;
;border-color: 16;
border-radius: 50%;
border-style:solid;
border-width:2px;
@media #{$medium-up} {
border-width: 2px;
};
@media #{$large-up} {
border-width: 2px;};
&:hover{border-color: 11;}
background-color: 2;
background-clip: padding-box;
&:hover {background-color: 11;}
width:3px;
height:3px;
@media #{$medium-up} {
width:3px;
height:3px;
};
@media #{$large-up} {
width:3px;
height:3px;
};
&:hover{background-color: 11;}
}}
 }
/* Responsive Menu:20 */
nav.responsive-menu {
.menu-item.MEC20{background-color: var(--clr-668);}
& .menu-item.MEC20, & .menu-item.MEC20 > div.menu-item-wrap{ & > a.MEC20, & > i{color: var(--clr-664);
text-transform: uppercase;
text-align: center;
}
  }
& .menu-item.MEC20 { border:0;

 border-bottom-width: 1px; }
& .sub-menu{ .sub-menu {}}

 }
/* login buttons:21 */
.MES21 {
background-color: var(--clr-665);
&:hover {background-color: var(--clr-669);}
color: #ffffff;
&:hover { color: #ffffff;}
border-radius: 50px;
padding: 10px 25px;

border-width: 1px;
border-style: solid;
border-color: transparent;
 }
/* Copyright panel grey:22 */
.MES22 {
background-color: var(--clr-668);
font-size: 12.8px;
@media #{$medium-up} {
font-size: 14.4px;
};
 }
.MES22 {
background-color: var(--clr-668);
font-size: 12.8px;
@media #{$medium-up} {
font-size: 14.4px;
};
 }
cite.MEC22{
font-size: 12.8px;
@media #{$medium-up} {
font-size: 14.4px;
};
}
/* carousel padding:23 */
.MES23 {
background-color: var(--clr-673);
padding: 1px;

 }
.MES23 {
background-color: var(--clr-673);
padding: 1px;

 }
/* Carousel grey pad:24 */
.MES24 {
background-color: var(--clr-667);
padding: 20px;

@media #{$medium-up} {
padding: 40px;

}
@media #{$large-up} {
padding: 70px 40px;

}
 }
.MES24 {
background-color: var(--clr-667);
padding: 20px;

@media #{$medium-up} {
padding: 40px;

}
@media #{$large-up} {
padding: 70px 40px;

}
 }
a.MEC24 { color: var(--clr-669);
&:hover { color: var(--clr-666);}
 }
/* border line:25 */
.MES25 {
& > hr {border-width: 1px 0 0 0;}
& > hr {border-color: var(--clr-667) transparent transparent transparent;}
& > hr {border-top-style: solid;}
 }
/* Top Strip Panel:26 */
.MES26 {
background-color: #ffffff;
color: var(--clr-665);
font-size: 16px;
@media #{$medium-up} {
font-size: 18px;
};
@media #{$large-up} {
font-size: 14.4px;
};
padding: 5px;

border-width: 0 0 1px 0;
border-style: solid;
border-color: transparent transparent var(--clr-668) transparent;
border-top-style: solid;
border-right-style: solid;
border-bottom-style: solid;
border-left-style: solid;
 }
.MES26 {
background-color: #ffffff;
color: var(--clr-665);
font-size: 16px;
@media #{$medium-up} {
font-size: 18px;
};
@media #{$large-up} {
font-size: 14.4px;
};
padding: 5px;

border-width: 0 0 1px 0;
border-style: solid;
border-color: transparent transparent var(--clr-668) transparent;
border-top-style: solid;
border-right-style: solid;
border-bottom-style: solid;
border-left-style: solid;
h1.MEC26, h2.MEC26, h3.MEC26, h4.MEC26, h5.MEC26, h6.MEC26 { color: var(--clr-666);
 }
h1.MEC26 { @media #{$medium-up} { font-size: 32px; }; }
h1.MEC26 { @media #{$large-up} { font-size: 40px; }; }
h2.MEC26 { @media #{$medium-up} { font-size: 25.6px; }; }
h2.MEC26 { @media #{$large-up} { font-size: 32px; }; }
h3.MEC26 { @media #{$medium-up} { font-size: 32px; }; }
h3.MEC26 { @media #{$large-up} { font-size: 40px; }; }
h4.MEC26 { @media #{$medium-up} { font-size: 20px; }; }
h4.MEC26 { @media #{$large-up} { font-size: 25px; }; }
h5.MEC26 { @media #{$medium-up} { font-size: 16px; }; }
h5.MEC26 { @media #{$large-up} { font-size: 20px; }; }
h6.MEC26 { @media #{$medium-up} { font-size: 48px; }; }
h6.MEC26 { @media #{$large-up} { font-size: 80px; }; }
 }
a.MEC26 { color: var(--clr-665);
&:hover { color: var(--clr-666);}
 }
cite.MEC26{
color: var(--clr-665);
font-size: 16px;
@media #{$medium-up} {
font-size: 18px;
};
@media #{$large-up} {
font-size: 14.4px;
};
}
/* Black text panel:27 */
.MES27 {
color: var(--clr-664);
 }
.MES27 {
color: var(--clr-664);
h1.MEC27, h2.MEC27, h3.MEC27, h4.MEC27, h5.MEC27, h6.MEC27 { color: var(--clr-664);
 }
 }
cite.MEC27{
color: var(--clr-664);
}
/* Dark:28 */
.MES28 {
background-color: var(--clr-664);
color: #ffffff;
&:hover { color: #ffffff;}
 }
/* Dark:29 */
.MES29 {
background-color: var(--clr-664);
color: #ffffff;
 }
.MES29 {
background-color: var(--clr-664);
color: #ffffff;
h1.MEC29, h2.MEC29, h3.MEC29, h4.MEC29, h5.MEC29, h6.MEC29 { color: #ffffff;
 }
 }
cite.MEC29{
color: #ffffff;
}
/* Shade 2:30 */
.MES30 {
background-color: var(--clr-674);
 }
/* Shade 2:31 */
.MES31 {
background-color: var(--clr-674);
color: #ffffff;
 }
.MES31 {
background-color: var(--clr-674);
color: #ffffff;
h1.MEC31, h2.MEC31, h3.MEC31, h4.MEC31, h5.MEC31, h6.MEC31 { color: #ffffff;
 }
 }
cite.MEC31{
color: #ffffff;
}
/* Main Menu White:32 */
nav.me-Menu.MES32 {
& .menu-item.MEC32, & .menu-item.MEC32 > div.MEC32{ & > a.MEC32{color: var(--clr-667);
text-transform: uppercase;
}
 &:hover > a.MEC32{color: var(--clr-666);
}
 }
&.horizontal > .menu-item.MEC32 { border:0;

 border-right-width: 1px;}
&.vertical > .menu-item.MEC32 { border:0;

border-bottom-width: 1px; }
&.horizontal > .menu-item.MEC32 .menu-item { border:0;
border-bottom-width: 1px;
 }
&.horizontal > .menu-item.MEC32 .sub-menu { border:1px;
 }
& > .menu-item > a{padding: 5px 0;}

@media #{$large-up} {
& > .menu-item > a{padding: 5px 20px;}

}
& .sub-menu .menu-item a{padding: 5px 0;}




.menu-item:hover {
& > .sub-menu, & > .pointer-wrap > .sub-menu {

}
}
 }
/* White text:33 */
.MES33 {
background-color: var(--clr-675);
color: #ffffff;
 }
.MES33 {
background-color: var(--clr-675);
color: #ffffff;
h1.MEC33, h2.MEC33, h3.MEC33, h4.MEC33, h5.MEC33, h6.MEC33 { color: #ffffff;
 }
 }
a.MEC33 { color: #ffffff;
&:hover { color: var(--clr-666);}
 }
cite.MEC33{
color: #ffffff;
}
/* Carousel White Pad:34 */
.MES34 {
background-color: #ffffff;
@media #{$medium-up} {
padding: 20px 15px;

}
@media #{$large-up} {
padding: 40px;

}
 }
.MES34 {
background-color: #ffffff;
@media #{$medium-up} {
padding: 20px 15px;

}
@media #{$large-up} {
padding: 40px;

}
 }
/* Transparent Dark:35 */
.MES35 {
background-color: var(--clr-675);
color: #ffffff;
 }
.MES35 {
background-color: var(--clr-675);
color: #ffffff;
h1.MEC35, h2.MEC35, h3.MEC35, h4.MEC35, h5.MEC35, h6.MEC35 { color: #ffffff;
 }
 }
cite.MEC35{
color: #ffffff;
}
/* Carousel Yellow Pad:36 */
.MES36 {
background-color: var(--clr-666);
color: #ffffff;
padding: 20px;

@media #{$medium-up} {
padding: 40px;

}
 }
.MES36 {
background-color: var(--clr-666);
color: #ffffff;
padding: 20px;

@media #{$medium-up} {
padding: 40px;

}
&:hover { h1.MEC36, h2.MEC36, h3.MEC36, h4.MEC36, h5.MEC36, h6.MEC36 { color: #ffffff;} }
 }
a.MEC36 { color: var(--clr-665);
&:hover { color: var(--clr-669);}
 }
cite.MEC36{
color: #ffffff;
}
/* Carousel Red Pad:37 */
.MES37 {
background-color: var(--clr-665);
color: #ffffff;
padding: 20px 15px;

@media #{$medium-up} {
padding: 40px;

}
 }
.MES37 {
background-color: var(--clr-665);
color: #ffffff;
padding: 20px 15px;

@media #{$medium-up} {
padding: 40px;

}
&:hover { h1.MEC37, h2.MEC37, h3.MEC37, h4.MEC37, h5.MEC37, h6.MEC37 { color: #ffffff;} }
 }
a.MEC37 { color: var(--clr-666);
&:hover { color: var(--clr-674);}
 }
cite.MEC37{
color: #ffffff;
}
/* Carousel Orange Pad:38 */
.MES38 {
background-color: var(--clr-669);
color: #ffffff;
padding: 20px;

@media #{$medium-up} {
padding: 40px;

}
 }
.MES38 {
background-color: var(--clr-669);
color: #ffffff;
padding: 20px;

@media #{$medium-up} {
padding: 40px;

}
&:hover { h1.MEC38, h2.MEC38, h3.MEC38, h4.MEC38, h5.MEC38, h6.MEC38 { color: #ffffff;} }
 }
a.MEC38 { color: var(--clr-672);
&:hover { color: var(--clr-667);}
 }
cite.MEC38{
color: #ffffff;
}
/* FAQ's:39 */
details.MES39 {
& > summary{background-color: var(--clr-676);
}
& > summary{padding: 10px;}

& > article{padding: 20px;}

& > article {border-width: 1px;
border-style: solid;
border-color: var(--clr-672);
} }
/* FAQ's dark:40 */
details.MES40 {
& > summary{background-color: var(--clr-675);
}
color: var(--clr-664);
& > article { color: var(--clr-664);
 }
& > summary{padding: 10px;}

& > article{padding: 20px;}

& > article {border-width: 1px;
border-style: solid;
border-color: var(--clr-675);
}& > summary { color: #ffffff;
 }
 }
/* Panel Hover Black:41 */
.MES41 {
background-color: transparent;
&:hover, &.hover { background-color: var(--clr-664);}
color: #ffffff;
 }
.MES41 {
background-color: transparent;
&:hover, &.hover { background-color: var(--clr-664);}
color: #ffffff;
h1.MEC41, h2.MEC41, h3.MEC41, h4.MEC41, h5.MEC41, h6.MEC41 { color: #ffffff;
 }
 }
cite.MEC41{
color: #ffffff;
}
/* yellow buttons:42 */
.MES42 {
background-color: var(--clr-666);
&:hover {background-color: var(--clr-669);}
color: #ffffff;
&:hover { color: #ffffff;}
border-radius: 50px;
padding: 10px 25px;

border-width: 1px;
border-style: solid;
border-color: transparent;
 }
/* orange buttons:43 */
.MES43 {
background-color: var(--clr-669);
&:hover {background-color: var(--clr-674);}
color: #ffffff;
&:hover { color: #ffffff;}
border-radius: 50px;
padding: 10px 25px;

border-width: 1px;
border-style: solid;
border-color: transparent;
 }
/* Orange Heading:44 */
.MES44 {
 }
.MES44 {
h1.MEC44, h2.MEC44, h3.MEC44, h4.MEC44, h5.MEC44, h6.MEC44 { color: var(--clr-669);
 }
 }
/* White email panel:46 */
.MES46 {
background-color: #ffffff;
 }
.MES46 {
background-color: #ffffff;
 }
/* Page Accordion:47 */
details.MES47 {
 }
